<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="justify-content-center auth-container">
        <div class="card">
          <div class="card-body p-0">
            <div class="p-5">
              <div
                v-if="config && showLogo"
                class="mx-auto mb-5"
                style="min-height:60px;height:100%;"
              >
                <a routerLink="/" t>
                  <img
                    v-if="config.logo"
                    :src="config.logo"
                    style="display:block;margin:auto;max-height:60px;max-width:100%;"
                  />
                  <h3 v-else class="d-inline align-middle ml-1 text-logo">{{
                    config.name
                  }}</h3>
                </a>
              </div>

              <h6 class="h5 mb-0 mt-4">Welcome back!</h6>
              <p class="text-muted mt-1 mb-4">
                Enter your email address and password.
              </p>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form
                  class="login-form"
                  @submit.prevent="handleSubmit(handleLogin)"
                >
                  <div class="form-group">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <feather
                            type="mail"
                            class="align-middle icon-dual"
                          ></feather>
                        </span>
                      </div>
                      <b-form-input
                        v-model="credentials.email"
                        type="text"
                        required
                        placeholder="Enter email"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <label class="form-control-label">Password</label>
                    <router-link
                      :to="{ name: 'passwords.request' }"
                      class="float-right text-muted text-unline-dashed ml-1"
                      >Forgot your password?</router-link
                    >
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <feather
                            type="lock"
                            class="align-middle icon-dual"
                          ></feather>
                        </span>
                      </div>
                      <b-form-input
                        v-model="credentials.password"
                        type="password"
                        required
                        placeholder="Enter your password"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        id="checkbox-signin"
                        v-model="credentials.remember_me"
                        type="checkbox"
                        class="custom-control-input"
                        checked
                      />
                      <label class="custom-control-label" for="checkbox-signin">
                        Remember me
                      </label>
                    </div>
                  </div>
                  <b-form-group id="button-group" class="mt-4 mb-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btn-block"
                      :disabled="loadingLogin"
                    >
                      <b-spinner v-if="loadingLogin" small /> Log In
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        remember_me: false,
      },
      loadingLogin: false,
      query: {},
    }
  },

  computed: {
    config() {
      return this.$store.getters['app/config']
    },
    showLogo() {
      const host = location.hostname
      if (host === 'app.broadcastengine.io' || host === 'mylps.io')
        return false
      return true
    }
  },

  mounted() {
    this.query = this.$route.query;
  },

  methods: {
    handleLogin() {
      this.loadingLogin = true
      this.$store
        .dispatch('auth/login', this.credentials)
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.loadingLogin = false
              if (this.query && this.query.path)
              {
                this.$router.push(this.query.path)
              } else {
                if (user.role === 'Agency' && user.agency.twilioConfig && user.agency.twilioConfig.status === 'Trial') {
                  this.$router.push('/' + user.role.toLocaleLowerCase() + '?warning=trial')
                } else {
                  this.$router.push('/' + user.role.toLocaleLowerCase())
                }
              }
            })
            .catch(() => {
              this.loadingLogin = false
            })
        })
        .catch(() => {
          this.loadingLogin = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-container {
  width: 460px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }

  .input-group-text {
    padding: 5px 10px;
  }
}
</style>
